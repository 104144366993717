/*Color Styling Variables */

:root {
    /* --- Highlight Configurations --- */
        --highlight-overlap: #C2EBC9;

        --highlight-deficient_practice_statement: #FFE4AD;
        --highlight-sample: #C1E8F0;
        --highlight-survey_action: #FFE4AD;
        --highlight-recipient: #C1E8F0;
        --highlight-extent: #C1E8F0;

        --facility-card-bg-nonactive: #ffffff;
        --facility-card--bg-active: #F8F8F8;
        --deficiency-card-bg: #F8F8F8;
        --deficiency-count-clr: #0071BC;
  }

  .custom-tab-panel {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;  
}

.custom-tab-panel-nopadding {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}
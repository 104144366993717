@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif !important;
}

#root, #app {
  height: 100%;
}

:root {
      --default-icon-color: #5B616B;
  /* --- Button Configurations --- */
      /* --Main Background Color */
      --primary-main: #0071BC;
      --tertiary-main: #FFFFFF;
      --disabled-main: #F1F1F1;
      /* --Secondary Background Color */
      --primary-secondary: #205493;
      --tertiary-secondary: #FFFFFF;
      --disabled-secondary: #D6D7D9;
      /* --Text Color */
      --primary-text: #FFFFFF;
      --tertiary-text: #0071BC;
      --tertiary-text2: #205493;
      --disabled-text: #5B616B;
      /* --Focus Color */
      --primary-focus: #0071BC;
      
      /* --Outline Color */
      --primary-outlineText: #0071BC;

      /* --Outline Border Color */
      --primary-outlineBorder: #0096F4;

  /* --- InfoIcon Config --- */
      --standard-bg: #E1F3F8;
      --standard-border: #02BFE7;
      --standard-color: #242424;


      --complaint-bg: #F9DEDE;
      --complaint-border: #E31C3D;
      --complaint-color: #242424;

      --infection-bg: #FFF1D2;
      --infection-border: #FDB81E;
      --infection-color: #242424;

      --nonfilled-color: #F1F1F1;


      --crit-bg: #E31C3D;
      --crit-border: #E31C3D;
      --crit-color: #FFFFFF;
      --not-crit-bg: #5B616B;
      --not-crit-border: #5B616B;
      --not-crit-color: #FFFFFF;
      
      --name-bg: #FFFFFF;
      --name-border: #0071BC;
      --name-color: #0071BC;

      --all-scrollbar-barandarrows-color: #e4e4e4;
      --all-scrollbar-hover-barandarrows-color: #a8bbbf;
      --secondary-scroll-background-color: #ffffff;

}

.all_scrollbars::-webkit-scrollbar {
  width: 17px;
}
.all_scrollbars::-webkit-scrollbar-thumb {
  background-color: var(--all-scrollbar-barandarrows-color);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.all_scrollbars::-webkit-scrollbar-thumb:hover {
  background-color: var(--all-scrollbar-hover-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-track {
  background-color: white; /* Available space on the bar */
  width: 17px;
}
#panel_scrollbar::-webkit-scrollbar {
  width: 17px;
}
#panel_scrollbar::-webkit-scrollbar-button {
  display: none;
}
/* Style the scrollbar buttons */
#panel_scrollbar::-webkit-scrollbar-button {
  background-color: #AEB0B5; /* Color for the scrollbar buttons */
  background-clip: padding-box;
  border: 0;
  height: 17px;
  width: 17px;
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:start:decrement,
#panel_scrollbar::-webkit-scrollbar-button:vertical:end:increment,
#panel_scrollbar::-webkit-scrollbar-button:horizontal:end:increment,
#panel_scrollbar::-webkit-scrollbar-button:horizontal:start:decrement {
  background-color: #AEB0B5; /* Color for the scrollbar buttons */
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:start:decrement:hover,
#panel_scrollbar::-webkit-scrollbar-button:vertical:end:increment:hover,
#panel_scrollbar::-webkit-scrollbar-button:horizontal:end:increment:hover,
#panel_scrollbar::-webkit-scrollbar-button:horizontal:start:decrement:hover {
  background-color: var(--all-scrollbar-hover-barandarrows-color); /* Hover color for scrollbar buttons */
}
#panel_scrollbar {
  --increment-bg: 
      linear-gradient(300deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(60deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(180deg, var(--panel-color) 30%, rgba(0, 0, 0, 0) 31%);
  --decrement-bg: 
      linear-gradient(120deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(240deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(0deg, var(--panel-color) 30%, rgba(0, 0, 0, 0) 31%);
  --horizontal-increment-bg: 
      linear-gradient(210deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(330deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(90deg, var(--panel-color) 30%, rgba(0, 0, 0, 0) 31%);
  --horizontal-decrement-bg: 
      linear-gradient(30deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(150deg, var(--panel-color) 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(270deg, var(--panel-color) 30%, rgba(0, 0, 0, 0) 31%);
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:start:decrement {
  background: var(--decrement-bg);
  background-color: var(--all-scrollbar-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:end:increment {
  background: var(--increment-bg);
  background-color: var(--all-scrollbar-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:horizontal:end:increment {
  background: var(--horizontal-increment-bg);
  background-color: var(--all-scrollbar-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:horizontal:start:decrement {
  background: var(--horizontal-decrement-bg);
  background-color: var(--all-scrollbar-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:start:decrement:hover {
  background: var(--decrement-bg);
  background-color: var(--all-scrollbar-hover-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:vertical:end:increment:hover {
  background: var(--increment-bg);
  background-color: var(--all-scrollbar-hover-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:horizontal:end:increment:hover {
  background: var(--horizontal-increment-bg);
  background-color: var(--all-scrollbar-hover-barandarrows-color);
}
#panel_scrollbar::-webkit-scrollbar-button:horizontal:start:decrement:hover {
  background: var(--horizontal-decrement-bg);
  background-color: var(--all-scrollbar-hover-barandarrows-color);
}


.header {
    height: 72px;

}

.header p {
    height: 56px;
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;


}

.sub-header {
    height: 48px;
    background-color: #1E2E4E;
}

.header-dropdown {
        float: right; /* Align the dropdown to the right */
        font-weight: 300;
      
}

.sidebar.collapsed {
  width: 50px; /* or whatever width you need */
}
.sidebar {
  width: 150px;
}
/* Adjust the width of the sidebar when expanded */
.sidebar.expanded {
  width: 150px; /* or whatever width you need */
}

  
  .sidebar-header-expanded {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-left: 110px;
  }

  .sidebar-header-collapsed {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    padding: 10px;
      }
  
  .expand-icon {
    margin-right: 10px;
  }
  
.Resizer {
  position: relative;
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}


.Resizer::before,
.Resizer::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 5%;
    background-color: #5B616B;
}

.Resizer::after {
    left: calc(50% - 1px); /* Adjust the distance between the two bars as needed */
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.pane {
  height: 100%;
  overflow: auto;
}

.pane:first-child {
  background-color: #f0f0f0;
}

.pane:last-child {
  background-color: #ffffff;
}

.SplitPane .Vertical {
  height: 100%;
  position: relative;
}
.SplitPane {
  height: 100%;
  position: relative;
}

.SplitPane .Pane {
  width: 100%;
  height: 100%;
}

.SplitPane .Resizer {
  background: #D6D7D9;
  opacity: 1;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.SplitPane .Resizer:hover {
  transition: all 2s ease;
}

.SplitPane .Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.SplitPane .Resizer.vertical {
  width: 20px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
  border: 50px;
  margin-bottom: 15%;
}

.Pane.vertical.Pane1 {
  flex: 1 1 0%;
  width: 100px;
    position: relative;
    outline: none;
    margin-left: 10%;

}



primary-nav {
  align-items: center;
  height: 50px;
  left: 0px;
  padding: 15px;
  width: 100%;
  margin: 0px;
  background-color: darkgray;
  display: inline;
  position: fixed;
  top: 70px;
  z-index: 3;
  font-weight: bold;
}

.search-container {
  align-items: center;
  display: flex;
  justify-content: left;
}

form .no-submit {
  width: 180px;
  align-items: center;
  color: white;
  right: 0;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin: 0 0;
}

input.no-submit {
  border: 1px solid white;
  width: 100%;
  padding: 9px 4px 9px 4px;
  /* You can use your image but having cleaner code is better, so I suggest saving the file and just linking it*/
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z'/%3E%3C/svg%3E") ;*/
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/55/Magnifying_glass_icon.svg");
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

input[type="search"] {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 7px 8px 7px 30px;
  font-size: 16px;
  color: inherit;
  border: 1px solid black;
  border-radius: inherit;
  width: 350px;
}

input[type="search"]::placeholder {
  color: white;
}

input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #3f69a8;
  border-color: #3f69a8;
  outline: none;
}
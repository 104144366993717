.css-11hlwqc > .MuiGrid-item {
    padding-left: 4px !important;
    padding-top: 0px !important;
}

.css-1ixds2g{
    padding: 8px !important
}


.centerContent{
    display: flex;
    align-items: center;
    gap: 8px;
}
.css-nu04pq{
    height: auto !important;
}

.css-1pt22uq{
    display: block !important;
}

.css-jciv6r {
    padding: 0 8px !important;
}

#element-text-box:hover{
    background-color: #F8F8F8;
    cursor: pointer;
}
.sidebar-menu {
    list-style-type: none; /* Removes default bullet styles */
    padding: 0; /* Removes padding */
    margin: 0; /* Ensures no default margin */
}

.sidebar-menu li {
    display: flex; /* Enables flexbox for horizontal alignment */
    align-items: center; /* Centers items vertically within the li */
    padding: 10px; /* Provides padding around the content */
    height: 40px; /* Fixed height for each item */
    overflow: hidden; /* Prevents content from overflowing */
    font-size: 16px;
    border-left: 6px solid white; /* Blue left border for active items */

}


.sidebar-menu li:hover {
    background-color: #F8F8F8; /* Light background for active items */
    border-left: 6px solid #F8F8F8; /* Blue left border for active items */
}

.sidebar-link {
    display: flex;
    align-items: center; /* Ensures vertical center alignment of the icon and text */
    text-decoration: none; /* Removes underline from links */
    color: #242424; /* Sets the text color */
    width: 100%; /* Ensures the link uses the full width of its container */
    gap: 8px; /* Space between icon and text */
}

.sidebar-icon, .sidebar-icon-expanded {
    color: #205493; /* Standard icon color */
    width: 16px; /* Icon width */
    height: 16px; /* Icon height */
}

.activeLink {
    border-left: 6px solid #0096F4 !important; /* Blue left border for active items */
    font-weight: 500; /* Bold text for active items */
}

.inactiveLink {
    font-weight: 400; /* Normal weight for inactive items */
}

.icon-text-container {
    flex-grow: 1; /* Allows container to fill space in li */
    justify-content: flex-start; /* Aligns content to the left */
}

.icon-text-container span {
    margin-left: 5px; /* Space between icon and text */
}
p {
    font-weight: 500;
    margin-bottom: 5px;
    }

.css-15v22id-MuiAccordionDetails-root{
    padding: 8px 0px 16px !important;
}

ul#facility-name-autocomplete-listbox li.MuiAutocomplete-option {
    text-transform: capitalize;
}
.facility-name-autocomplete .MuiAutocomplete-root .MuiChip-label {
    text-transform: capitalize;
}

.filtersContainer{
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}

/*.MuiFormControl-root .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {*/
/*    border-color: inherit !important;*/
/*  }*/

/*  !* Remove red label color when in error state *!*/
/*  .MuiFormControl-root .Mui-error {*/
/*    color: inherit !important;*/
/*  }*/
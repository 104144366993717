#surveysContainer{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 144px);
    border: 10pt solid #D6D7D9;
    width: 100%; 
    min-height: 0;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
}
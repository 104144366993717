
.Container {
	width: 100%;
	display: flex;
	flex-direction: column !important;
	gap: 1rem;
}

.Panel {
    display: flex;
    flex-direction: column;
}

.PanelContent {
    background-color: var(--panel-color);
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    min-height: 0 !important;  
}

#ResizeHandleOuter {
    flex: 0 0 20px;
    position: relative;
    outline: none;
    background-color: transparent;
    cursor: ew-resize	 !important;
}

#styles_ResizeHandleOuter__1Y7mW{
  cursor: ew-resize	 !important;
}

.ResizeHandleOuter[data-resize-handle-active] {
    background-color: rgba(255, 255, 255, 0.2);
}
  
.ResizeHandleInner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    color: #5B616B;
    background-image: url('../../assets/ResizePanelIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.2s linear;
    cursor: ew-resize	 !important;
}

.textsvg_container {
  display: flex;
  gap: 4px;
  align-items: center;
}

.textsvg_container .left_col {
  flex: 0 0 auto;
}

.textsvg_container .right_col {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}